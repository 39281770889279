import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import {
  clearClient,
  setClientID,
  setProjectID,
} from "../../../../Redux/Actions/actions";
import { fetchAdminRows } from "../../Admin/AdminQueries/useAdminRows";
import { fetchClientRows } from "../../Client/ClientQueries/useClientRows";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import useCurrentProject from "../Form/FormQueries/useCurrentProject";
import "./styles/dashboard-app-bar.css";

const DashboardAppBar = () => {
  const {
    queryClient,
    navigate,
    clientId,
    userId,
    projectId,
    isAdmin,
    heading,
    openModal,
    openSidebar,
    setOpenSidebar,
    setOpenModal,
  } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const location = useLocation();

  // event handler for navigating to the form page, and clearing redux state if necessary
  const handleCreateNewRequest = async (e) => {
    if (isAdmin) {
      dispatch(clearClient());
      dispatch(setClientID(null));
      dispatch(setProjectID(null));
      navigate("/dashboard/form");
    } else {
      dispatch(setProjectID(null));
      navigate(`/dashboard/form/${clientId}`);
    }
  };

  // event handler for returning to the dashboard, and enusring data grid data
  const handleReturnToDashboard = async () => {
    if (location.pathname.startsWith("/dashboard/form") && projectId) {
      const projectData = await queryClient.fetchQuery({
        queryKey: ["currentProject", clientId, projectId],
        queryFn: () => useCurrentProject(clientId, projectId),
      });

      console.log("APP BAR PROJECT DATA: ", projectData);

      if (
        projectData &&
        projectData.requestStatus === "IP" &&
        userId === projectData.activeUserId
      ) {
        await axios.patch(`/api/update-form-status/${clientId}/${projectId}/`, {
          requestStatus: "I",
          userId: userId,
        });
      }

      if (isAdmin) {
        dispatch(clearClient());
        dispatch(setClientID(null));
        dispatch(setProjectID(null));

        await queryClient.ensureQueryData({
          queryKey: ["adminRows"],
          queryFn: () => fetchAdminRows(isAdmin),
        });
        navigate("/dashboard/admin", { replace: true });
      } else {
        dispatch(setProjectID(null));
        if (!userId) {
          setOpenModal(true);
        } else {
          setOpenModal(false);
        }
        await queryClient.ensureQueryData({
          queryKey: ["clientRows", clientId],
          queryFn: () => fetchClientRows(clientId),
        });
        navigate(`/dashboard/client/${clientId}`, { replace: true });
      }
    } else {
      if (!userId) {
        setOpenModal(true);
      } else {
        setOpenModal(false);
        if (isAdmin) {
          dispatch(clearClient());
          dispatch(setClientID(null));
          dispatch(setProjectID(null));
          navigate("/dashboard/admin", { replace: true });
        } else {
          dispatch(setProjectID(null));
          navigate(`/dashboard/client/${clientId}`, { replace: true });
        }
      }
    }
  };
  return (
    <AppBar position="fixed" id="app-bar">
      <Toolbar>
        <div id="left-portal">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            className="sidebar-hamburger"
            id="sidebar-hamburger"
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            {openSidebar === false ? (
              <MenuIcon id="hamburger-closed" />
            ) : (
              <CloseIcon id="hamburger-opened" />
            )}
          </IconButton>
          <h4 id="portal-heading">{heading}</h4>
        </div>
        {heading.includes("Dashboard") ? (
          <Button
            id="create-new-request-btn"
            color="inherit"
            onClick={handleCreateNewRequest}
            disabled={openModal || openSidebar}
          >
            <AddIcon id="add-request" />
            <span className="appbar-btn-text" id="add-request-span">
              New Request
            </span>
          </Button>
        ) : (
          <Button
            id="return-home-btn"
            aria-label="return-to-dashboard"
            onClick={handleReturnToDashboard}
            disabled={openModal || openSidebar}
          >
            <HomeIcon id="return-home-icon" />
            <span className="appbar-btn-text" id="return-home-span">
              Return Home
            </span>
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default DashboardAppBar;

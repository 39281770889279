// NEXT BUTTON HANDLER (Used in timeline & form btns)
export async function handleNext(
  e,
  clientId,
  currentProjectID,
  slide1,
  pageNumber,
  setPageNumber,
  newPageNumber,
  createForm,
  queryClient,
) {
  e.preventDefault();

  if (pageNumber === 1 && !currentProjectID) {
    await createForm.mutateAsync(slide1);

    if (createForm.status === "success") {
      setPageNumber(2);
    }
  } else if (pageNumber > 0 && currentProjectID) {
    queryClient.invalidateQueries({
      queryKey: ["predictiveText", clientId],
    });

    setPageNumber(+newPageNumber);
  } else if (pageNumber === 0) {
    setPageNumber(+newPageNumber);
  }
}

// Verify that the email matched proper formatting
export const isValidEmail = (email, optional = true) => {
  if (optional === true && email === "") return true;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

// Verify that the name matched proper formatting
export const isValidName = (name, optional = true) => {
  // must include at least first and last name separated by a space & can include a hyphen (-)
  if (optional === true && name === "") return true;

  let nameArray = name.split(" ");

  return nameArray.length >= 2 && nameArray[1] !== "";
};

// Verify that the phone number matched proper formatting
export const isValidPhoneNumber = (phone, optional = true) => {
  // must be exactly 12 digits long (dashes are dynamically added) (###-###-####)
  if (optional === true && phone.length === 0) return true;
  if (optional === false && phone.length === 0) return false;
  const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
  return phonePattern.test(phone);
};

// Verify that the form is ready to be submitted
export const verifySubmission = (slideStatus) => {
  // if all slides except slide9 have a value equal === 'complete' then return true
  // slideStatus is an object with keys 'slide1', 'slide2', up to 'slide10' and the values can be 'complete', or 'incomplete'
  let verified = false;
  for (let slide in slideStatus) {
    if (
      slide === "slide9" &&
      slideStatus["slide1"] === "complete" &&
      slideStatus["slide2"] === "complete" &&
      slideStatus["slide3"] === "complete" &&
      slideStatus["slide4"] === "complete" &&
      slideStatus["slide5"] === "complete" &&
      slideStatus["slide6"] === "complete" &&
      slideStatus["slide7"] === "complete" &&
      slideStatus["slide8"] === "complete"
    ) {
      verified = true;
      continue;
    } else if (
      slide === "slide9" &&
      (slideStatus["slide1"] === "incomplete" ||
        slideStatus["slide2"] === "incomplete" ||
        slideStatus["slide3"] === "incomplete" ||
        slideStatus["slide4"] === "incomplete" ||
        slideStatus["slide5"] === "incomplete" ||
        slideStatus["slide6"] === "incomplete" ||
        slideStatus["slide7"] === "incomplete" ||
        slideStatus["slide8"] === "incomplete")
    ) {
      verified = false;
      break;
    } else {
      verified = true;
    }
  }
  return verified;
};
